import React from "react";
import "./ExplicationRipey.css";
import { Link } from "react-router-dom";

const GlowingHoverCard = () => {
  return (
    <div className="container-ripey">
      <div className="card-ripey"></div>
    </div>
  );
};

export default GlowingHoverCard;
