// Modal.js
import React from "react";
import "./Modal.css"; // Assurez-vous d'ajouter les styles pour le modal

const Modal = ({ isOpen, onClose, professor }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay-nuestroProfesores">
      <div className="modal-content-nuestroProfesores">
        <button className="modal-close" onClick={onClose}>
          &times;
        </button>
        <img
          src={professor.photo}
          alt={`Photo de ${professor.name}`}
          className="modal-photo-nuestroProfesores"
        />
        <h2 className="modal-name-nuestroProfesores">{professor.name}</h2>
        <p className="modal-profession-nuestroProfesores">
          {professor.profession}
        </p>
        <p className="modal-bio-nuestroProfesores">{professor.bio}</p>
      </div>
    </div>
  );
};

export default Modal;
